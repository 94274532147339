import { Component, OnInit } from '@angular/core';
import { NewOrderService } from 'src/app/services/new-order/new-order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { plLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ReservationService } from 'src/app/services/reservations/reservation.service';
import { DeliveryAddress, Reservation } from 'src/app/services/reservations/reservation';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.css']
})
export class NewOrderComponent implements OnInit {
  reservationForm: FormGroup;
  submitted = false;
  machineId;
  machineName;
  machineDescription;
  machinePriceByDay;
  machineImagePath;
  disabledDates = [];
  minDate;
  maxDate;
  deliveryOptions;
  isDeniedDateInPeriod = false;
  isDelivery = false;
  
  constructor(private newOrderService: NewOrderService, private route: ActivatedRoute, private router: Router,
    private localeService: BsLocaleService, private reservationService: ReservationService, private fb: FormBuilder) { }

  ngOnInit() {
    if (this.newOrderService.machineId === undefined) {
      this.router.navigate(['/']);
    }

    this.deliveryOptions = [
      { label: 'do 5km', value: 1, cost: 10 },
      { label: 'do 10 km', value: 2, cost: 20 },
      { label: 'do 20 km', value: 3, cost: 30 },
      { label: 'do 30 km', value: 4, cost: 40 },
      { label: 'do 40 km', value: 5, cost: 50 },
    ];

    this.machineId = this.newOrderService.machineId;
    this.machineName = this.newOrderService.machineName;
    this.machineDescription = this.newOrderService.machineDescription;
    this.machinePriceByDay = this.newOrderService.machinePriceByDay;
    this.machineImagePath = 'api/machines/' + this.machineId + '/image';
    defineLocale('pl', plLocale);
    this.localeService.use('pl');

    this.reservationForm = new FormGroup({
      dateRange: new FormControl('', Validators.required),
      deliveryRadio: new FormControl('', Validators.required),
      deliveryOptions: new FormControl(1, Validators.required),
      zipCode: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      street: new FormControl('', Validators.required),
      homeNumber: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', [Validators.required, Validators.pattern('[0-9 ]{9}')]),
      email: new FormControl('', [Validators.required, Validators.email]),
      description: new FormControl('', Validators.nullValidator),
      acceptTerms: new FormControl(false, Validators.requiredTrue)
    });


    this.minDate = new Date();
    this.maxDate = new Date(this.minDate.getTime() + (1000 * 60 * 60 * 24 * 90));
    this.reservationService.getMachineReservationsPeriods(this.machineId, this.minDate, this.maxDate)
      .subscribe(periods => {
        periods.forEach(p => {
          this.disabledDates = this.reservationService.getAllDaysFromPeriod(new Date(p.dateFrom), new Date(p.dateTo));
        });
      });
  }

  newOrder() {
    if (!this.isDelivery) {
      this.reservationForm.get('zipCode').clearValidators();
      this.reservationForm.get('zipCode').updateValueAndValidity();      
      this.reservationForm.get('city').clearValidators();
      this.reservationForm.get('city').updateValueAndValidity();
      this.reservationForm.get('street').clearValidators();
      this.reservationForm.get('street').updateValueAndValidity();
      this.reservationForm.get('homeNumber').clearValidators();
      this.reservationForm.get('homeNumber').updateValueAndValidity();

    } 

    if (this.isDeniedDateInPeriod) {
      this.reservationForm.controls.dateRange.setErrors({ incorrect: true });
    }

    this.submitted = true;
    if (this.reservationForm.invalid) {
      return;
    }

    const reservation = new Reservation();
    reservation.machineId = this.machineId;
    reservation.firstName = this.reservationForm.get('firstName').value;
    reservation.lastName = this.reservationForm.get('lastName').value;
    reservation.phoneNumber = this.reservationForm.get('phoneNumber').value;
    reservation.description = this.reservationForm.get('description').value;
    reservation.email = this.reservationForm.get('email').value;
    reservation.dateFrom = this.reservationForm.get('dateRange').value[0];
    reservation.dateTo = this.reservationForm.get('dateRange').value[1];
    reservation.price = this.countCost();
    if (this.isDelivery) {
      const delivery = new DeliveryAddress()
      delivery.deliveryDistance = this.reservationForm.get('deliveryOptions').value;
      delivery.zipCode = this.reservationForm.get('zipCode').value;
      delivery.city = this.reservationForm.get('city').value;
      delivery.street = this.reservationForm.get('street').value;
      delivery.homeNumber = this.reservationForm.get('homeNumber').value;
      reservation.delivery = delivery;
    }
    this.reservationService.create(reservation).subscribe(
      r => { 
        this.newOrderService.reservation = r;
        this.router.navigate(['order-summary', {}]); 
      },
      err => alert("Nie udało się dokonać rezerwacji. Spróbuj ponownie później."));
  }

  numberOfDays(dateFrom, dateTo) {
    return this.reservationService.getNumberOfDaysFromPeriod(dateFrom, dateTo);
  }

  onDateChange($event) {
    if ($event == null) {
      return;
    }
    const dateFrom = new Date($event[0]);
    const dateTo = new Date($event[1]);
    const numberOfInvalidDates = this.reservationService.countDaysIncludedInPeriod(dateFrom, dateTo, this.disabledDates);

    if (numberOfInvalidDates === 0) {
      this.isDeniedDateInPeriod = false;
      this.reservationForm.controls.dateRange.setErrors(null);
    } else {
      this.isDeniedDateInPeriod = true;
    }
  }

  countCost() {
    let deliveryCost = 0;
    if(this.isDelivery && this.reservationForm.get('deliveryOptions').value != undefined) {
      const deliveryOption = this.deliveryOptions.filter(option => option.value == this.reservationForm.get('deliveryOptions').value)[0];
      deliveryCost = deliveryOption.cost;
    }
    return this.machinePriceByDay * this.numberOfDays(this.reservationForm.get('dateRange').value[0], this.reservationForm.get('dateRange').value[1]) 
      + deliveryCost;
  }

}
