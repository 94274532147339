import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MachinesService } from 'src/app/services/machines/machines.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Machine } from 'src/app/services/machines/machine';

@Component({
  selector: 'app-new-machine',
  templateUrl: './new-machine.component.html',
  styleUrls: ['./new-machine.component.css']
})
export class NewMachineComponent implements OnInit {
  @ViewChild('labelImport', { static: false })
  labelImport: ElementRef;
  machineForm: FormGroup;
  fileToUpload: File = null;
  submitted = false;
  isImageTooBig = false;

  constructor(private machinesService: MachinesService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.machineForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.nullValidator),
      priceByDay: new FormControl('', Validators.required),
      image: new FormControl('', Validators.nullValidator)
    });
  }

  createMachine() {
    this.submitted = true;
    if (this.machineForm.invalid) {
      return;
    }
    const name = this.machineForm.get('name').value;
    const description = this.machineForm.get('description').value;
    const priceByDay = this.machineForm.get('priceByDay').value;

    this.machinesService.add(new Machine(name, description, priceByDay)).subscribe(
      data => {
        if (this.fileToUpload == null) {
          this.router.navigate(['/machine-administrator', {}]);
        }
        if (this.fileToUpload != null) {
          this.machinesService.uploadImage(data.id, this.fileToUpload).subscribe(
            f => this.router.navigate(['/machine-administrator', {}]),
            err => console.log(err)
          );
        }
      },
      err => console.log(err) // todo: show toast with message
    );
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if (this.fileToUpload.size > 300000) {
      this.isImageTooBig = true;
      this.fileToUpload = null;
      return;
    }
    this.isImageTooBig = false;
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
  }
}
