import { Component, OnInit } from '@angular/core';
import { NewOrderService } from 'src/app/services/new-order/new-order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MachinesService } from 'src/app/services/machines/machines.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactService } from 'src/app/services/contact/contact.service';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { EmailMessage } from 'src/app/services/contact/EmailMessage';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  machines = [];
  visibleMachines = [];
  machinesImages: Map<number, string | ArrayBuffer> = new Map<number, string | ArrayBuffer>();
  machinesImageLoading: Map<number, boolean> = new Map<number, boolean>();
  contactForm: FormGroup;
  submitted = false;

  constructor(private newOrderService: NewOrderService, private route: ActivatedRoute, private router: Router,
    private machinesService: MachinesService, private formBuilder: FormBuilder, private contactService: ContactService) { }

  ngOnInit() {
    this.machinesService.getAllWithFilter(['ENABLED', 'DISABLED_TEMPORARY']).subscribe(
      data => {
        this.machines = data;
        this.visibleMachines = data;
        this.machines.forEach(machine => {
          this.getImageFromService(machine.id);
        });
      }
    );

    this.contactForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      email: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  newOrder(machineId) {
    const machine = this.machines.filter(m => m.id === machineId)[0];
    if (machine.status === 'DISABLED_TEMPORARY') {
      return;
    }
    this.newOrderService.machineId = machine.id;
    this.newOrderService.machineName = machine.name;
    this.newOrderService.machineDescription = machine.description;
    this.newOrderService.machinePriceByDay = machine.priceByDay;

    this.router.navigate(['/new-order', {}]);
  }

  createImageFromBlob(machineId: number, image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.machinesImages.set(machineId, reader.result);
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getImageFromService(machineId: number) {
    this.machinesImageLoading.set(machineId, true);
    this.machinesService.getImage(machineId).subscribe(data => {
      this.createImageFromBlob(machineId, data);
      this.machinesImageLoading.set(machineId, false);
    }, error => {
      this.machinesImageLoading.set(machineId, false);
      console.log(error);
    });
  }

  onSearchChange(searchValue: string): void {
    if (!searchValue) {
      this.visibleMachines = this.machines;
    } else {
      const searchWords = searchValue.split(' ').filter(el => el.length !== 0);
      this.visibleMachines = this.machines.filter(m => {
        return searchWords.some(word => m.name.toLocaleLowerCase().indexOf(word.toLocaleLowerCase()) > -1)
          || searchWords.some(word => m.description.toLocaleLowerCase().indexOf(word.toLocaleLowerCase()) > -1);
      });
    }
  }

  sendMessage() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    const message = new EmailMessage();
    message.subject = 'Formularz kontaktowy';
    message.text = 'Imię: ' + this.contactForm.controls.firstname.value + '\nEmail: ' + this.contactForm.controls.email.value
      + '\nWiadomość: ' + this.contactForm.controls.message.value ;
    this.contactService.send(message).subscribe(data => {
      alert('Wiadomość została wysłana');
      this.contactForm.reset();
    }, err => {
      alert('Nie udało wysłać się wiadomości. Spróbuj później.');
    }
    );
  }
}
