import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Machine } from './machine';


@Injectable({
  providedIn: 'root'
})
export class MachinesService {

  machines = [];

  constructor(private http: HttpClient) {}

  getAll(): Observable<Machine[]> {
    return this.http.get<Machine[]>('/api/machines');
  }

  getAllWithFilter(statuses: any[]): Observable<Machine[]> {
    const httpParams = new HttpParams().set('statuses', statuses.join(','));
    return this.http.get<Machine[]>('/api/machines', { params: httpParams });
  }

  add(machine: Machine): Observable<Machine> {
    return this.http.post<Machine>('/api/machines', machine);
  }

  update(machine: Machine, id: number): Observable<Machine> {
    return this.http.put<Machine>('/api/machines/' + id, machine);
  }

  delete(machineId: number): Observable<any> {
    return this.http.delete(`/api/machines/${machineId}`);
 }

  uploadImage(machineId: number, image: File) {
    const formData: FormData = new FormData();
    formData.append('file', image, image.name);

    return this.http.post('/api/machines/' + machineId + '/image', formData);
  }

  getImage(machineId: number): Observable<Blob> {
    return this.http.get('/api/machines/' + machineId + '/image', { responseType: 'blob' });
  }
}
