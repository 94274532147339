import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { NewOrderComponent } from './components/new-order/new-order.component';
import { BsDatepickerModule, DatepickerModule,  } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NewMachineComponent } from './components/new-machine/new-machine.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { BasicAuthHtppInterceptorService } from './services/jwt/basic-auth-http-interceptor.service';
import { MachineAdministratorComponent } from './components/machine-administrator/machine-administrator.component';
import { ReservationAdministratorComponent } from './components/reservation-administrator/reservation-administrator.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import localePl from '@angular/common/locales/pl';
import { registerLocaleData } from '@angular/common';
import { defineLocale, plLocale } from 'ngx-bootstrap/chronos';
import { UpdateReservationComponent } from './components/update-reservation/update-reservation.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { StatusMapperPipe } from './pipes/status-mapper.pipe';
import { OrderSummaryComponent } from './components/order-summary/order-summary.component';

registerLocaleData(localePl);
defineLocale('pl', plLocale);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NewOrderComponent,
    NewMachineComponent,
    LoginComponent,
    LogoutComponent,
    MachineAdministratorComponent,
    ReservationAdministratorComponent,
    UpdateReservationComponent,
    StatusMapperPipe,
    OrderSummaryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    BrowserAnimationsModule,
    NoopAnimationsModule,
    BsDropdownModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    PopoverModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [
    {provide: LOCALE_ID, useValue: "pl"},
    {
      provide : HTTP_INTERCEPTORS,
      useClass: BasicAuthHtppInterceptorService,
      multi   : true,
    }],
  bootstrap: [AppComponent],
  entryComponents: [UpdateReservationComponent]
})
export class AppModule { }
