export class Machine {
    id: number;
    name: string;
    description: string;
    priceByDay: number;
    dateCreated: Date;
    dateLastChange: Date;
    status: string;

    constructor(name: string, description: string, priceByDay: number) {
        this.name = name;
        this.description = description;
        this.priceByDay = priceByDay;
      }
}
