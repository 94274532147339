import { Component } from '@angular/core';
import { AuthGaurdService } from './services/jwt/auth-gaurd.service';
import { AuthenticationService } from './services/jwt/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isCollapsed = true;
  title = 'machine-reservation-fronted';

  constructor(private authService: AuthenticationService) { }

  get isUserLoggedIn() { return this.authService.isUserLoggedIn }

  get logOut() { return this.authService.logOut() }
}
