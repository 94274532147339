import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Machine } from 'src/app/services/machines/machine';
import { MachinesService } from 'src/app/services/machines/machines.service';

@Component({
  selector: 'app-machine-administrator',
  templateUrl: './machine-administrator.component.html',
  styleUrls: ['./machine-administrator.component.css']
})
export class MachineAdministratorComponent {
  @ViewChild('labelImport', { static: false })
  labelImport: ElementRef;
  machines: Machine[] = [];
  editedMachineId = null;
  editedMachine: Machine = null;
  fileToUpload: File = null;
  isImageTooBig = false;
  machineStates = [
    {name: 'DISABLED', value: 'niedostępna'},
    {name: 'DISABLED_TEMPORARY', value: 'tymczasowo niedostępna'},
    {name: 'ENABLED', value: 'dostępna'}
  ];

  constructor(private machinesService: MachinesService) { }

  public fetchMachines(event) {
    if (!JSON.parse(event.target.ariaExpanded)) {
      this.machinesService.getAll().subscribe(
        data => this.machines = data
      );
    }
  }

  public delete(machineId: number) {
    this.machinesService.delete(machineId).subscribe(
      x => this.machines = this.machines.filter(m => m.id !== machineId),
      err => console.error('Connection problem'),
    );
  }

  public update(machine) {
    this.machinesService.update(machine, machine.id).subscribe(
      data => {
        if (this.fileToUpload != null) {
          this.machinesService.uploadImage(data.id, this.fileToUpload).subscribe();
        }
        const index = this.machines.findIndex((m, i) => machine.id === m.id);
        this.machines[index] = data;
        this.editedMachineId = null;
        this.editedMachine = null;
      },
      err => console.log(err)
    );
  }

  public edit(machine) {
    this.editedMachineId = machine.id;
    this.editedMachine = { ...machine };
  }

  public cancel() {
    this.editedMachineId = null;
    this.editedMachine = null;
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if (this.fileToUpload.size > 300000) {
      this.isImageTooBig = true;
      this.fileToUpload = null;
      return;
    }
    this.isImageTooBig = false;
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
  }
}
