import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Machine } from '../machines/machine';
import { Observable } from 'rxjs';
import { EmailMessage } from './EmailMessage';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  send(message: EmailMessage): Observable<void> {
    return this.http.post<void>('/api/mail', message);
  }
}
