import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReservationService } from 'src/app/services/reservations/reservation.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-update-reservation',
  templateUrl: './update-reservation.component.html',
  styleUrls: ['./update-reservation.component.css']
})
export class UpdateReservationComponent implements OnInit {
  reservationForm: FormGroup;
  @Input() reservation;
  machineImagePath;
  minDate;
  maxDate;
  disabledDates = [];
  isDeniedDateInPeriod = false;
  statuses;
  deliveryOptions;  
  isDelivery = false;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(private reservationService: ReservationService, public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.statuses = [
      {name: 'Nowa', value: 'NEW'},
      {name: 'Zatwierdzona', value: 'APPROVED'},
      {name: 'Rozpoczęta', value: 'STARTED'},
      {name: 'Zakończona', value: 'FINISHED'},
      {name: 'Odrzucona', value: 'REMOVED'}
    ];

    this.deliveryOptions = [
      {label: 'do 5km', value: 1, cost: '10'},
      {label: 'do 10 km', value: 2, cost: '20'},
      {label: 'do 20 km', value: 3, cost: '30'},
      {label: 'do 30 km', value: 4, cost: '40'},
      {label: 'do 40 km', value: 5, cost: '50'},
    ];

    this.reservationForm = new FormGroup({
      dateRange: new FormControl('', Validators.required),
      deliveryRadio: new FormControl('', Validators.required),
      deliveryOptions: new FormControl('', Validators.required),
      zipCode: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      street: new FormControl('', Validators.required),
      homeNumber: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      email: new FormControl('', Validators.nullValidator),
      description: new FormControl('', Validators.nullValidator),
      status: new FormControl('', Validators.required),
      paid: new FormControl('', Validators.nullValidator)
    });

    this.machineImagePath = 'api/machines/' + this.reservation.machine.id + '/image';

    this.reservation.dateRange = [new Date(this.reservation.dateFrom), new Date(this.reservation.dateTo)];
    this.reservation.deliveryOptions = this.deliveryOptions.filter(option => option.value == this.reservation.deliveryDistance)[0];
    if (this.reservation.deliveryDistance != undefined) {
        this.reservation.deliveryRadio = true;
    } else {
      this.reservation.deliveryRadio = false;
    } 

    console.log(this.reservation);

    this.reservationForm.patchValue(this.reservation);

    console.log(this.reservationForm);


    this.minDate = new Date();
    this.maxDate = new Date(this.minDate.getTime() + (1000 * 60 * 60 * 24 * 90));
    this.reservationService.getMachineReservationsPeriods(this.reservation.machine.id, this.minDate, this.maxDate)
      .subscribe(periods => {
        periods.forEach(p => {
          this.disabledDates = this.reservationService.getAllDaysFromPeriod(new Date(p.dateFrom), new Date(p.dateTo));
        });
      });
  }

  updateReservation(reservation) {
    const updatedReservation =  this.reservationForm.getRawValue();
    console.log(updatedReservation.deliveryDistance)
    if (updatedReservation.deliveryDistance =! undefined) {
      this.isDelivery = true;
    }
    updatedReservation.dateFrom = this.reservationForm.get('dateRange').value[0];
    updatedReservation.dateTo = this.reservationForm.get('dateRange').value[1];
    updatedReservation.machine = reservation.machine;

    this.reservationService.update(reservation.id, updatedReservation).subscribe(
      r => {
        this.event.emit({reservation: r});
        this.bsModalRef.hide();
      },
      err => console.log(err)
    );
  }

  numberOfDays(dateFrom, dateTo) {
    this.reservationService.getNumberOfDaysFromPeriod(dateFrom, dateTo);
  }

  onDateChange($event) {
    const dateFrom = new Date($event[0]);
    const dateTo = new Date($event[1]);
    const numberOfInvalidDates = this.reservationService.countDaysIncludedInPeriod(dateFrom, dateTo, this.disabledDates);

    if (numberOfInvalidDates === 0) {
      this.isDeniedDateInPeriod = false;
      this.reservationForm.controls.dateRange.setErrors(null);
    } else {
      this.isDeniedDateInPeriod = true;
    }
  }

}
