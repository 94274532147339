import { Machine } from '../machines/machine';

export class Reservation {
    id: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    description: string;
    dateFrom: Date;
    dateTo: Date;
    dateCreated: Date;
    dateLastChange: Date;
    machineId: number;
    machine: Machine;
    delivery: DeliveryAddress;
    price: number;
}

export class DeliveryAddress {
    deliveryDistance: string;
    zipCode: any;
    city: any; 
    street: any;
    homeNumber: any;
}