import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { NewOrderComponent } from './components/new-order/new-order.component';
import { NewMachineComponent } from './components/new-machine/new-machine.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AuthGaurdService } from './services/jwt/auth-gaurd.service';
import { MachineAdministratorComponent } from './components/machine-administrator/machine-administrator.component';
import { ReservationAdministratorComponent } from './components/reservation-administrator/reservation-administrator.component';
import { OrderSummaryComponent } from './components/order-summary/order-summary.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'new-order', component: NewOrderComponent},
  { path: 'new-machine', component: NewMachineComponent, canActivate: [AuthGaurdService]},
  { path: 'machine-administrator', component: MachineAdministratorComponent, canActivate: [AuthGaurdService]},
  { path: 'reservation-administrator', component: ReservationAdministratorComponent, canActivate: [AuthGaurdService]},
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGaurdService] },
  { path: 'order-summary', component: OrderSummaryComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
