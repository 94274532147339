import { Component, OnInit } from '@angular/core';
import { listLocales } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Machine } from 'src/app/services/machines/machine';
import { Reservation } from 'src/app/services/reservations/reservation';
import { ReservationService } from 'src/app/services/reservations/reservation.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UpdateReservationComponent } from '../update-reservation/update-reservation.component';

@Component({
  selector: 'app-reservation-administrator',
  templateUrl: './reservation-administrator.component.html',
  styleUrls: ['./reservation-administrator.component.css']
})
export class ReservationAdministratorComponent implements OnInit {

  reservations: Reservation[];
  machineDetailsHtml: string;
  bsModalRef: BsModalRef;

  constructor(private reservationService: ReservationService, private localeService: BsLocaleService, 
              private modalService: BsModalService) { }

  ngOnInit(): void {
    this.localeService.use('pl');
    this.reservationService.getAll().subscribe(
      data => this.reservations = data
    );
  }

  showMachineDetails(machine: Machine) {
    this.machineDetailsHtml = `<div class="font-weight-light description">${machine.description}</div>
          <div class="mb-4 p-1 price-shadow">${machine.priceByDay} zł / dobę</div>`;
  }

  getDatePickerRange(drp, from, to) {
    if (typeof drp._bsValue === 'undefined') {
      drp.bsValue = [new Date(from), new Date(to)];
    }
  }

  editReservation(reservation) {
    const initialState = {
      reservation
    };
    this.bsModalRef = this.modalService.show(UpdateReservationComponent, {initialState});
    this.bsModalRef.content.closeBtnName = 'Zamknij';

    this.bsModalRef.content.event.subscribe(res => {
      const i = this.reservations.findIndex(r => res.reservation.id === r.id);
      this.reservations[i] = res.reservation;
    });
  }
}
