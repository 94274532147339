import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Reservation } from './reservation';
import { Observable } from 'rxjs';
import { ReservationPeriod } from './reservationPeriod';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(private http: HttpClient) { }

  create(reservation: Reservation): Observable<Reservation> {
    return this.http.post<Reservation>('/api/reservations', reservation);
  }

  update(reservationId: number, reservation: Reservation): Observable<Reservation> {
    return this.http.put<Reservation>('/api/reservations/' + reservationId, reservation);
  }

  getAll(): Observable<Reservation[]> {
    return this.http.get<Reservation[]>('/api/reservations');
  }

  getMachineReservationsPeriods(machineId, dateFrom, dateTo): Observable<ReservationPeriod[]> {
    const httpParams = new HttpParams().set('machine-id', machineId).set('date-from', dateFrom.toISOString().substring(0, 10))
      .set('date-to', dateTo.toISOString().substring(0, 10));
    return this.http.get<ReservationPeriod[]>('/api/reservations-periods', { params: httpParams });
  }

  getAllDaysFromPeriod(dateFrom, dateTo): Date[] {
    const listDays = [];

    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore.getDate() - 1);
    listDays.push(new Date(oneDayBefore.toISOString().substring(0, 10)));

    const oneDayAfter = new Date(dateTo);
    oneDayAfter.setDate(oneDayAfter.getDate() + 1);
    listDays.push(new Date(oneDayAfter.toISOString().substring(0, 10)));

    for (const dateLoop = dateFrom; dateLoop <= dateTo; dateLoop.setDate(dateLoop.getDate() + 1)) {
      listDays.push(new Date(dateLoop.toISOString().substring(0, 10)));
    }

    return listDays;
  }

  getNumberOfDaysFromPeriod(dateFrom, dateTo): number {
      const diffInMs = Math.abs(dateTo - dateFrom);
      return 1 + Math.round(diffInMs / (1000 * 60 * 60 * 24));
  }

  countDaysIncludedInPeriod(dateFrom, dateTo, days) {
    let numberOfDays = 0;
    for (const dateLoop = dateFrom; dateLoop <= dateTo; dateLoop.setDate(dateLoop.getDate() + 1)) {
      if (this.isInArray(days, dateLoop)) {
        numberOfDays += 1;
      }
    }

    return numberOfDays;
  }

  isInArray(array, value) {
    return !!array.find(item => item.getDate() === value.getDate());
  }
}
