import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewOrderService } from 'src/app/services/new-order/new-order.service';
import { Reservation } from 'src/app/services/reservations/reservation';
import { ReservationService } from 'src/app/services/reservations/reservation.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css']
})
export class OrderSummaryComponent implements OnInit {
  reservation: Reservation;

  constructor(private newOrderService: NewOrderService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if (this.newOrderService.reservation === undefined) {
      this.router.navigate(['/']);
    }
    this.reservation = this.newOrderService.reservation;
  }

}
