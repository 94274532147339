
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../services/jwt/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  invalidLogin = false
  redirectURL: string;

  constructor(private formBuilder: FormBuilder, private router: Router,
    private route: ActivatedRoute, private loginservice: AuthenticationService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    console.log('user is logged: ' + this.loginservice.isUserLoggedIn());
  }

  get f() { return this.loginForm.controls; }

  checkLogin() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.loginservice.authenticate(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          const params = this.route.snapshot.queryParams;
          if (params.redirectURL) {
            this.redirectURL = params.redirectURL;
          }
          if (this.redirectURL) {
            this.router.navigate([this.redirectURL]);
          } else {
            this.router.navigate(['']);
          }
          this.invalidLogin = false;
        },
        error => {
          this.error = 'Nieprawidłowe dane logowania';
          this.invalidLogin = true;
          this.loading = false;
        });
  }

}