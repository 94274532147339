import { Injectable } from '@angular/core';
import { Reservation } from '../reservations/reservation';

@Injectable({
  providedIn: 'root'
})
export class NewOrderService {

  machineId;
  machineDescription;
  machineName;
  machinePriceByDay;

  reservation: Reservation;

  constructor() { }
}
