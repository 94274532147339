import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusMapper'
})
export class StatusMapperPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let status;
    switch (value) {
      case 'NEW': {
         status = 'Nowa';
         break;
      }
      case 'APPROVED': {
         status = 'Zatwierdzona';
         break;
      }
      case 'STARTED': {
        status = 'Rozpoczęta';
        break;
     }
      case 'FINISHED': {
        status = 'Zakończona';
        break;
      }

      case 'REMOVED': {
        status = 'Odrzucona';
        break;
      }
      default: {
         status = 'Status Nieznany';
         break;
      }
    }
    return status;
  }

}
